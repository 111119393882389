<template>
  <section>
    <b-form-group
      label="Valor Unitário"
      label-for="service-value"
    >
      <b-form-input
        id="service-value"
        v-model="accounts.value"
        autofocus
        trim
        placeholder="Valor Unitário"
        @change="$emit('update:accounts', accounts)"
      />
    </b-form-group>

    <b-form-group
      label="Descrição"
      label-for="service-description"
    >
      <quill-editor
        v-model="accounts.description"
        @change="$emit('update:accounts', accounts)"
      />
    </b-form-group>
  </section>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BFormGroup,
    BFormInput,
    quillEditor
  },
  model: {
    prop: 'accounts',
    event: 'update:accounts'
  },
  props: {
    accounts: {
      type: Object,
      required: true
    }
  }
}
</script>
