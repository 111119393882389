<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      :width="[1, 3].includes(selected) ? '800px' : ''"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Nome"
            label-for="service-name"
          >
            <b-form-input
              id="service-name"
              v-model="formData.name"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Imagem"
            label-for="service-image"
          >
            <b-form-input
              id="service-image"
              v-model="formData.image"
              autofocus
              trim
              placeholder="Imagem do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Tipo"
            label-for="service-type"
          >
            <b-form-select
              id="service-type"
              v-model="selected"
              :state="selected === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione um tipo!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Custos Fixos"
            label-for="fixed_costs"
          >
            <b-form-input
              id="fixed_costs"
              v-model="formData.fixed_costs"
              autofocus
              trim
              placeholder="Custos Fixos"
            />
          </b-form-group>

          <b-form-group
            v-if="selected === 1"
            label="Descrição"
            label-for="service-description"
          >
            <quill-editor v-model="formData.description" />
          </b-form-group>

          <accounts
            v-if="selected === 3"
            v-model="accounts_info"
          />

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { quillEditor } from 'vue-quill-editor'
import Accounts from './Accounts.vue'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    quillEditor,
    Accounts
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    created_item: {},
    formData: {
      name: '',
      image: '',
      type: 1,
      fixed_costs: 0,
      deactivated: 1,
      description: null
    },
    accounts_info: {
      value: '',
      description: ''
    },
    options: [
      {
        value: 1,
        text: 'SMS'
      },
      {
        value: 2,
        text: 'Seguidores'
      },
      {
        value: 3,
        text: 'Contas'
      },
      {
        value: 4,
        text: 'Pack de Fotos'
      },
      {
        value: 5,
        text: 'VPS'
      }
    ],
    selected: null
  }),
  methods: {
    clearForm () {
      this.formData = {
        name: '',
        image: '',
        fixed_costs: 0,
        deactivated: 1,
        description: null
      }

      this.accounts_info = {
        value: '',
        description: ''
      }
    },
    async handleSubmit () {
      const serviceId = this.$router.currentRoute.params.id
      const url = '/admin/service_options'
      const params = {
        service_id: serviceId,
        name: this.formData.name,
        image: this.formData.image,
        type: this.selected,
        fixed_costs: this.formData.fixed_costs,
        description: this.formData.description,
        deactivated: 1,
        accounts_info: this.accounts_info
      }

      this.$http
        .post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Criado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
