<template>
  <section>
    <b-form-group
      label="Plano da VPS"
      label-for="vps-plan"
    >
      <b-form-select
        id="vps-plan"
        v-model="vps.type"
        :state="vps.type === null ? false : true"
        :options="VPSOptions"
        @change="$emit('update:vps', vps)"
      >
        <template #first>
          <b-form-select-option
            :value="null"
          >Por favor, selecione um tipo!</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Valor Unitário"
      label-for="value"
    >
      <b-form-input
        id="value"
        v-model="vps.value"
        autofocus
        trim
        placeholder="Valor Unitário"
        @change="$emit('update:vps', vps)"
      />
    </b-form-group>

    <b-form-group
      label="Descrição"
      label-for="description"
    >
      <b-form-input
        id="description"
        v-model="vps.description"
        autofocus
        trim
        placeholder="Valor Unitário"
        @change="$emit('update:vps', vps)"
      />
    </b-form-group>

    <b-form-group
      label="Garantia (Em dias)"
      label-for="guarantee"
    >
      <b-form-input
        id="guarantee"
        v-model="vps.guarantee"
        autofocus
        trim
        placeholder="Garantia (Em dias)"
        @change="$emit('update:vps', vps)"
      />
    </b-form-group>

    <b-form-checkbox
      v-model="popular"
      @change="$emit('update:vps', vps)"
    >
      Destaque
    </b-form-checkbox>

    <b-card>
      <b-row>
        <b-col
          v-for="(item, index) of benefits"
          :key="index"
          cols="12"
        >
          <b-row>
            <b-col
              sm="2"
              md="2"
              cols="12"
            >
              <b-button
                style="margin-top: 17px; margin-left: 10px"
                variant="relief-primary"
                class="btn-icon"
                size="sm"
                @click.stop="removeBenefit(index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>

            <b-col
              sm="10"
              md="10"
              cols="12"
            >
              <b-form-input
                v-model="benefits[index]"
                class="mt-1 mb-1"
                placeholder="Benefício"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          class="mt-1 mb-1"
        >
          <b-button
            variant="relief-primary"
            block
            @click.stop="addBenefit('')"
          >
            Adicionar Benefício
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BButton,
  BInputGroupPrepend
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BInputGroupPrepend
  },
  model: {
    prop: 'vps',
    event: 'update:vps'
  },
  props: {
    vps: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    popular: false,
    benefits: [],
    VPSOptions: [
      {
        value: 1,
        text: 'Plano Básico'
      },
      {
        value: 2,
        text: 'Plano Médio'
      },
      {
        value: 3,
        text: 'Plano Avançado'
      }
    ]
  }),
  watch: {
    benefits (val) {
      this.vps.benefits = JSON.stringify(val)
      this.$emit('update:vps', this.vps)
    }
  },
  mounted () {
    this.prepare()
  },
  methods: {
    addBenefit (benefit) {
      this.benefits.push(benefit)
    },
    removeBenefit (id) {
      this.benefits = this.benefits.filter((item, index) => index !== id)
    },
    prepare () {
      this.benefits = this.vps.benefits === null ? [] : JSON.parse(this.vps.benefits)
      this.popular = !!this.vps.popular
    }
  }
}
</script>
