<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-body>
          <b-card-title>
            Opções de Serviço
            <b-button
              variant="relief-primary"
              class="float-right"
              @click.stop="createModal = true"
            >
              Adicionar
            </b-button>
          </b-card-title>
        </b-card-body>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Por Página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Ordenar Por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- Nenhum --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="my-1"
          >
            <b-form-group
              label="Buscar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Escreva o que deseja pesquisar."
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(image)="data">
                <img
                  class="rounded-lg"
                  :src="`${$api_host}/storage/assets/${data.item.image}`"
                  width="35px"
                >
              </template>

              <template #cell(type)="data">
                <b-button
                  :disabled="loading"
                  class="mr-1"
                  size="sm"
                  :variant="getType(data.item.type).color"
                >
                  {{ getType(data.item.type).message }}
                </b-button>
              </template>

              <template #cell(deactivated)="data">
                <b-button
                  v-b-tooltip.hover.top="'Ativar/Desativar'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="data.item.deactivated === 0 ? 'success' : 'danger'"
                  class="btn-icon rounded-circle"
                  @click.stop="updateStatus(data.item.id)"
                >
                  <feather-icon
                    :icon="
                      data.item.deactivated === 0 ? 'UnlockIcon' : 'LockIcon'
                    "
                  />
                </b-button>
              </template>

              <template #cell(functions)="data">
                <b-button
                  v-if="data.item.type !== 3"
                  v-b-tooltip.hover.top="''"
                  :disabled="loading"
                  class="mr-1"
                  size="sm"
                  variant="relief-dark"
                  @click.stop="goTo(data.item)"
                >
                  <feather-icon
                    v-if="!loading"
                    icon="ListIcon"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>

                <b-button
                  v-if="data.item.type === 3"
                  v-b-tooltip.hover.top="''"
                  :disabled="loading"
                  class="mr-1"
                  size="sm"
                  variant="relief-dark"
                  @click.stop="goTo(data.item)"
                >
                  <feather-icon
                    v-if="!loading"
                    icon="UserPlusIcon"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>

                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  :disabled="loading"
                  class="mr-1"
                  size="sm"
                  variant="relief-primary"
                  @click.stop="open(data.item.id)"
                >
                  <feather-icon
                    v-if="!loading"
                    icon="EditIcon"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>

                <b-button
                  v-b-tooltip.hover.top="'Deletar'"
                  :disabled="loading"
                  size="sm"
                  variant="relief-danger"
                  @click.stop="deleteModal(data.item)"
                >
                  <feather-icon
                    v-if="!loading"
                    icon="Trash2Icon"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <Create
      v-model="createModal"
      @updated="created($event)"
    />

    <Edit
      v-model="editModal"
      :service-data="serviceData"
      @updated="updated($event)"
    />
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BButton,
  VBTooltip,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TableMixin from './TableMixin'
import IndexMixin from './IndexMixin'
import Create from './Create.vue'
import Edit from './Edit.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BButton,
    BSpinner,
    Create,
    Edit
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mixins: [TableMixin, IndexMixin],
  data: () => ({
    fields: [
      {
        key: 'name',
        label: 'nome',
        sortable: true
      },
      {
        key: 'image',
        label: 'Foto'
      },
      {
        key: 'type',
        label: 'Tipo'
      },
      {
        key: 'deactivated',
        label: 'Disponível'
      },
      {
        key: 'functions',
        label: 'Funções'
      }
    ]
  })
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
