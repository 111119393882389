export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    serviceData: {}
  }),
  async mounted () {
    await this.getServiceOptions()
  },
  methods: {
    async getServiceOptions () {
      const serviceId = this.$router.currentRoute.params.id
      const url = `/admin/service_options/find_per_service_id/${serviceId}`
      await this.$http
        .get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    },
    async updateStatus (id) {
      const service = this.items.find(item => item.id === id)
      service.deactivated = service.deactivated === 0 ? 1 : 0
      const params = {
        deactivated: service.deactivated
      }

      await this.$http.patch(`/admin/service_options/${service.id}`, params)
    },
    getType (type) {
      let response = {}

      switch (type) {
      case 1:
        response = {
          message: 'SMS',
          color: 'relief-primary',
          to: 'Opções de SMS (Admin)'
        }
        break

      case 2:
        response = {
          message: 'Seguidores',
          color: 'relief-primary',
          to: 'Opções de Seguidores (Admin)'
        }
        break

      case 3:
        response = {
          message: 'Contas',
          color: 'relief-primary',
          to: 'Adicionar Contas (Admin)'
        }
        break

      case 4:
        response = {
          message: 'Pack de Fotos',
          color: 'relief-primary',
          to: ''
        }
        break

      case 5:
        response = {
          message: 'VPS',
          color: 'relief-primary',
          to: ''
        }
        break

      default:
        response = {
          message: 'Nenhum Serviço',
          color: 'relief-primary',
          to: ''
        }
      }

      return response
    },
    goTo (item) {
      const serviceId = this.$router.currentRoute.params.id
      const type = this.getType(item.type)

      return item.type !== 3
        ? this.$router.push({ name: type.to, params: { id: serviceId, option_id: item.id } })
        : this.$router.push({ name: type.to, query: { id: item.id } })
    },
    open (id) {
      this.serviceData = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.editModal = true
    },
    created (payload) {
      this.items.unshift(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar a Opção "${item.name}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/service_options/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'A Opção selecionada foi deletada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
